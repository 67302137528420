interface Props {
  isNameVisibile: boolean;
  height: number; //32
  width: number; //32
  isPill: boolean;
}

const Logo = ({ isPill, isNameVisibile, width, height }: Props) => {
  return (
    <a href="/" className="d-flex justify-content-start align-items-start text-body-emphasis text-decoration-none">
      <img
        className={`me-1 ${isPill ? 'rounded-pill' : 'rounded'}`}
        src="https://bloggrammer.com/assets/img/profile.png"
        alt="AlgoCollab logo"
        width={width}
        height={height}
      />
      {isNameVisibile && <span className="fs-4">AlgoCollab</span>}
    </a>
  );
};

export default Logo;
