import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AlgoToaster from './components/AlgoToaster';
import { UserProvider } from './context/UserContext';
import FailedLogin from './pages/FailedLogin';
import { initDarkMode } from './hooks/UseDarkMode';
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import Spinner from './components/Spinner';
import { Actions, Chat, NotificationDto, PlatformType, UserDto } from 'algocollab';
import socket from './libs/Socket';
import toast from 'react-hot-toast';
import { useSocket } from './hooks/useSocket';
import { getDocument } from './features/code/hooks/CollabExt';
import { lazy } from 'react';

const Home = lazy(() => import('./pages/Home'));
const Platforms = lazy(() => import('./pages/Platforms'));
const Faq = lazy(() => import('./pages/Faq'));
const Rooms = lazy(() => import('./pages/Rooms'));
const PersonalProfile = lazy(() => import('./pages/PersonalProfile'));
const PublicProfile = lazy(() => import('./pages/PublicProfile'));
const JoinRoom = lazy(() => import('./pages/JoinRoom'));
const Leaderboard = lazy(() => import('./pages/Leaderboard'));
const PrivateRoom = lazy(() => import('./pages/PrivateRoom'));
const AcceptInvite = lazy(() => import('./pages/AcceptInvite'));
const NotFound = lazy(() => import('./pages/NotFound'));
const PublicRoom = lazy(() => import('./pages/PublicRoom'));
const Error500 = lazy(() => import('./pages/Error500'));

const App = () => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [notifications, setNotifications] = useState<NotificationDto[]>([]);
  const [chats, setChats] = useState<Chat[]>([]);
  const [users, setUsers] = useState<UserDto[]>([]);
  const [version, setVersion] = useState<number>(0);
  const [doc, setDoc] = useState<string | null>(null);
  const [documentName, setDocumentName] = useState<string>('');
  const chatRef = useRef<HTMLDivElement>(null);
  const { onConnect, onDisconnect, onPeersInvite, onCollabError, onCollaborators, onMessageReceived } = useSocket({
    chats,
    chatRef,
    notifications,
    setUsers,
    setChats,
    setIsConnected,
    setNotifications,
  });
  initDarkMode();

  const onErrors = useCallback((err: Error) => {
    socket.removeAllListeners();
    socket.disconnect();
    toast.error('Connection failed, try again later.');
  }, []);
  const onLogout = useCallback(async () => {
    socket.removeAllListeners();
    socket.disconnect();
  }, []);

  const handleEditorInit = useCallback(() => {
    getDocument(socket, documentName).then(({ version, doc }) => {
      setVersion(version);
      setDoc(doc.toString());
    });
  }, [documentName]);
  const onDocumentDisplay = useCallback((docName: string) => {
    getDocument(socket, docName).then(({ version, doc }) => {
      setVersion(version);
      setDoc(doc.toString());
      setDocumentName(docName);
    });
  }, []);

  useEffect(() => {
    socket.connect();
    socket.on(Actions.CONNECT, onConnect);
    socket.on(Actions.DISCONNECT, onDisconnect);
    socket.on(Actions.ERROR_CONNECTING, (err: Error) => onErrors(err));
    socket.on(Actions.FAILED_CONNECTION, (err: Error) => onErrors(err));
    socket.on(Actions.TOAST_MESSAGE, onLogout);
    socket.on(Actions.INVITE_PEERS_FOR_COLLAB, onPeersInvite);
    socket.on(Actions.COLLABORATORS, onCollaborators);
    socket.on(Actions.DISPLAY_DOC, onDocumentDisplay);
    socket.on(Actions.CHAT_MESSAGE, onMessageReceived);
    socket.on(Actions.ERROR_500, onCollabError);

    return () => {
      socket.off(Actions.CONNECT, onConnect);
      socket.off(Actions.DISCONNECT, onDisconnect);
      socket.off(Actions.ERROR_CONNECTING, (err: Error) => onErrors(err));
      socket.off(Actions.FAILED_CONNECTION, (err: Error) => onErrors(err));
      socket.off(Actions.INVITE_PEERS_FOR_COLLAB, onPeersInvite);
      socket.off(Actions.COLLABORATORS, onCollaborators);
      socket.off(Actions.DISPLAY_DOC, onDocumentDisplay);
      socket.off(Actions.CHAT_MESSAGE, onMessageReceived);
      socket.off(Actions.ERROR_500, onCollabError);
      socket.off(Actions.PULL_UPDATE_RESPONSE);
      socket.off(Actions.PUSH_UPDATE_RESPONSE);
      socket.off(Actions.GET_DOC_RESPONSE);
      onLogout();
    };
  }, []);

  return (
    <UserProvider>
      <AlgoToaster />
      <BrowserRouter>
        <Suspense fallback={<Spinner grow={false} />}>
          <Routes>
            <Route path="/" element={<Home onLogout={onLogout} />}></Route>
            <Route path="/platform" element={<Platforms onLogout={onLogout} />}></Route>
            <Route path="/faq" element={<Faq onLogout={onLogout} />}></Route>
            <Route path="/leetcode-rooms" element={<Rooms onLogout={onLogout} platform={PlatformType.LeetCode} />}></Route>
            <Route path="/hackerrank-rooms" element={<Rooms onLogout={onLogout} platform={PlatformType.Hackerrank} />}></Route>

            <Route
              path="/profile"
              element={<PersonalProfile onLogout={onLogout} setNotifications={setNotifications} notifications={notifications} />}
            ></Route>
            <Route path="/:username" element={<PublicProfile onLogout={onLogout} />}></Route>
            <Route path="/room" element={<JoinRoom />}></Route>
            {/* <Route path="/room/:roomId" element={<JoinRoom />}></Route> */}
            <Route path="/room/:roomId" element={<AcceptInvite />}></Route>
            <Route path="/leaderboard" element={<Leaderboard onLogout={onLogout} />}></Route>
            <Route
              path="/p/collab/:roomId"
              element={
                <PrivateRoom
                  onLogout={onLogout}
                  onNotify={setNotifications}
                  notifications={notifications}
                  handleEditorInit={handleEditorInit}
                  users={users}
                  doc={doc}
                  version={version}
                  chats={chats}
                  setChats={setChats}
                  chatRef={chatRef}
                  documentName={documentName}
                />
              }
            ></Route>
            <Route
              path="/g/collab/:data"
              element={
                <PublicRoom
                  onLogout={onLogout}
                  onNotify={setNotifications}
                  notifications={notifications}
                  handleEditorInit={handleEditorInit}
                  users={users}
                  doc={doc}
                  version={version}
                  chats={chats}
                  setChats={setChats}
                  chatRef={chatRef}
                  documentName={documentName}
                />
              }
            ></Route>
            <Route path="/login-failed" element={<FailedLogin />}></Route>
            <Route path="/500" element={<Error500 />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </UserProvider>
  );
};
export default App;
