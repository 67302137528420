import { useCallback } from 'react';
import { Chat, NotificationDto, UserDto } from 'algocollab';
import { getFromUtcTime12, getNotifyDate } from '../utils/DateTime';
import { scrollToBottom } from './UseScrollToBottom';
interface Prop {
  chats: Chat[];
  setChats: (value: React.SetStateAction<Chat[]>) => void;
  setUsers: (value: React.SetStateAction<UserDto[]>) => void;
  chatRef: React.RefObject<HTMLDivElement>;
  setNotifications: React.Dispatch<React.SetStateAction<NotificationDto[]>>;
  setIsConnected: React.Dispatch<React.SetStateAction<boolean>>;
  notifications: NotificationDto[];
}
export const useSocket = ({ chats, chatRef, notifications, setUsers, setChats, setIsConnected, setNotifications }: Prop) => {
  // const onToastReceived = useCallback((chat: Chat) => {
  //   if (!chat) return;
  //   toast.success(chat.message, {
  //     position: 'top-center',
  //   });
  // }, []);
  const onConnect = useCallback(() => {
    setIsConnected(true);
  }, []);
  const onDisconnect = useCallback((err: Error) => {
    setIsConnected(false);
  }, []);
  const onCollabError = useCallback((msg: string) => {
    window.location.href = '/500';
  }, []);
  const onMessageReceived = useCallback((chat: Chat) => {
    if (!chat) return;
    const newChat: Chat = {
      message: chat.message,
      timestamp: getFromUtcTime12(chat.timestamp),
      isMine: chat.isMine,
      sender: chat.sender,
      avatar: chat.avatar,
      _id: chat._id,
    };
    setChats((chats) => [...chats, newChat]);
    scrollToBottom(chatRef.current);
  }, []);
  const onCollaborators = useCallback((users: any) => {
    setUsers(users);
  }, []);
  const onPeersInvite = useCallback((notify: NotificationDto) => {
    if (!notify) return;
    const newNotify: NotificationDto = {
      title: notify.title,
      message: notify.message,
      displayDate: getNotifyDate(notify.displayDate),
      queryParam: notify.queryParam,
      read: notify.read,
      icon: notify.icon,
      id: notify.id,
    };
    setNotifications((notifications) => [...notifications, newNotify]);
  }, []);

  return {
    // onPeersInvite,
    onMessageReceived,
    onCollabError,
    onConnect,
    onDisconnect,
    onCollaborators,
    onPeersInvite,
  };
};
