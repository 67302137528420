export const getNotifyDate = (utcDate: string) => {
  const date = new Date(utcDate);

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
  }).format(date);
  return formattedDate;
};
export const getFromUtcTime24 = (utcDate: string) => {
  return new Date(utcDate).toLocaleTimeString('en-US', {
    timeStyle: 'short',
    hour12: false,
  });
};

export const getLocaleTime24 = (date: Date) => {
  return date.toLocaleTimeString('en-US', {
    timeStyle: 'short',
    hour12: false,
  });
};

export const getFromUtcTime12 = (utcDate: string) => {
  return new Date(utcDate).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

export const getLocaleTime12 = (date: Date) => {
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};
