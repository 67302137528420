interface Props {
  grow: boolean;
}

const LoadingIndicator = ({ grow }: Props) => {
  return (
    <div className="container min-vh-100 d-flex justify-content-center align-items-center">
      <div className={grow ? 'spinner-grow' : 'spinner-border'} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingIndicator;
