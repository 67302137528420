export const env = {
  REACT_APP_CLIENT_PORT: 3000,
  REACT_APP_SERVER_PORT: 5000,
  REACT_APP_TABLE_DEBOUNCE: 200,
  REACT_APP_CLIENT_URL: process.env.NODE_ENV === 'production' ? 'https://algocollab.com' : 'http://localhost:3000',
  REACT_APP_SERVER_URL: process.env.NODE_ENV === 'production' ? 'https://api.algocollab.com' : 'http://localhost:5000',
  REACT_APP_LEETCODE_HOST: 'https://leetcode.com',
  REACT_APP_LEETCODE_PROBLEMS: 'https://leetcode.com/problems',
  REACT_APP_HACKERRANK_PROBLEMS: 'https://www.hackerrank.com/challenges',
} as const;
