import React from 'react';
import { Toaster } from 'react-hot-toast';

const AlgoToaster = () => {
  return (
    <Toaster
      position="top-right"
      toastOptions={{
        success: {
          duration: 3000,
          //@ts-ignore
          //   theme: {
          //     primary: ColorCodes.BLACK,
          //     secondary: 'black',
          //   },
        },
      }}
    ></Toaster>
  );
};

export default AlgoToaster;
