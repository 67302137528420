import Logo from '../components/Logo';

const LoginHeader = () => {
  return (
    <header className="pb-2 mx-5 my-2 border-bottom d-flex flex-wrap align-items-center justify-content-center justify-content-md-between">
      <Logo isPill={true} isNameVisibile={true} height={32} width={32} />
    </header>
  );
};

export default LoginHeader;
