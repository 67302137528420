import { io } from 'socket.io-client';
import { env } from '../env/envalid';
import { DefaultEventsMap } from '@socket.io/component-emitter';

// without enabling sticky-session, you will experience HTTP 400
// errors due to "Session ID unknown" the WebSocket transport does
//  not have this limitation, since it relies on a single TCP
//  connection for the whole session. Which means that if you
//  disable the HTTP long-polling transport (which is a perfectly valid choice in 2021),
//   you won't need sticky sessions:
const socket = io(env.REACT_APP_SERVER_URL, {
  reconnectionAttempts: Infinity,
  timeout: 10000,
  // transports: ['websocket', 'polling'],
  // WARNING: in that case, there is no fallback to long-polling
  transports: ['websocket'], // or [ "websocket", "polling" ] (the order matters)
  path: '/socket.io', // This should match your Nginx location
  autoConnect: false,
});
export default socket;
